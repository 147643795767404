<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    VclList
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  directives: { money: VMoney },
  validations: {
    deposit: {
      value: { required },
    }
  },
  data() {
    return {
      balance: 'R$ -,--',

      loading: false,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },

      deposits: {
        loading: true,
        errored: false,
        body: null,
      },

      deposit: {
        modal: false,
        loading: false,
        value: '',
        payment: {
          qrcode: '',
          base64: '',
        }
      }
    };
  },
  methods: {
    getSaldo() {
      api.get("financeiro/saldo").then((res) => {
        if (res.data.status == "success") {
          this.balance = this.$options.filters.currency(res.data.balance);
        }
      });
    },
    getDepositos() {
      api.get("financeiro/depositos").then((res) => {
        if (res.data.status == "success") {
          this.deposits.body = res.data.list;
        }
      })
        .catch((error) => {
          this.deposits.errored = error;
        })
        .finally(() => {
          this.deposits.loading = false;
        });
    },
    createDeposito() {
      this.deposit.loading = true;

      api
        .post("financeiro/depositos", {
          value: this.deposit.value
        }).then((res) => {
          if (res.data.status == "success") {
            this.deposit.modal = true;
            this.deposit.payment.qrcode = res.data.deposit.payment.qrcode;
            this.deposit.payment.base64 = res.data.deposit.payment.base64;

            this.getDepositos();
          } else {
            this.deposit.modal = false;
            this.deposit.loading = false;
            this.$toast.error(res.data.message)
          }
        })
        .catch((error) => {
          if (error) {
            this.deposit.modal = false;
            this.deposit.loading = false;
          }
        })
        .finally(() => {
          this.deposits.loading = false;
        });
    },
    viewDeposito(id) {
      this.deposit.modal = true;

      api
        .get("financeiro/depositos/" + id).then((res) => {
          if (res.data.status == "success") {
            this.deposit.payment.qrcode = res.data.deposit.payment.qrcode;
            this.deposit.payment.base64 = res.data.deposit.payment.base64;
          }
        });
    }
  },
  mounted() {
    this.getSaldo();
    this.getDepositos();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/financeiro">Financeiro</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Adicionar saldo</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Adicionar saldo</h3>
        <p>Adicione saldo a sua conta para realizar seus pagamentos.</p>
      </div>
      <div class="page-title-right">
        <div class="card border-0 bg-soft-default mb-0">
          <div class="card-body py-3 d-flex align-items-center">
            <div class="font-size-15">Saldo disponível:</div>
            <h4 class="m-0 ml-2">{{ this.balance }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-lg-4">
            <b-form @submit.prevent="createDeposito">
              <b-form-group id="value" label="Valor" label-for="value">
                <b-form-input id="value" v-model="deposit.value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.deposit.value.$error }"></b-form-input>
                <div v-if="$v.deposit.value.$error" class="invalid-feedback">
                  <span v-if="!$v.deposit.value.required">O valor é obrigatório.</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="deposit.loading == true || this.deposit.value === 'R$ 0,00' || this.deposit.value === ''" type="submit" variant="default">
                  Adicionar
                  <b-spinner v-if="deposit.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
          <div class="col-lg-4 pt-3">
            <h6>Atenção</h6>
            <ul class="m-0">
              <li>Ao realizar um pagamento via Pix o seu saldo cai na mesma hora;</li>
              <li>Será cobrado uma taxa de 0,99% por cada transação;</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <strong>Últimos depósitos</strong>
      </div>
      <div class="card-body">
        <div v-if="deposits.errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="deposits.loading" class="col-md-6"></vcl-list>
        <div v-else-if="deposits.body && deposits.body.length == 0" class="text-center">
          <strong>Nenhuma movimentação no período.</strong><br />
          Ao depositar um valor na sua conta, os lançamentos aparecerão aqui.
        </div>
        <div v-else class="table-responsive">
          <table class="table table-nowrap table-style">
            <thead class="thead-light">
              <tr>
                <th>#</th>
                <th>Data</th>
                <th>Valor</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in deposits.body" :key="index">
                <td>{{ td.id.split('-')[0] }}</td>
                <td>{{ td.date }}</td>
                <td>
                  {{ td.value | currency }}
                </td>
                <td>
                  <span v-if="td.status === 'pending'" class="badge badge-soft-warning font-size-12">Aguardando pagamento</span>
                  <span v-else-if="td.status === 'approved'" class="badge badge-soft-success font-size-12">Pago</span>
                  <span v-else-if="td.status === 'canceled'" class="badge badge-soft-danger font-size-12">Cancelado</span>
                </td>
                <td class="text-right">
                  <button class="btn btn-default btn-sm btn-rounded" v-on:click="viewDeposito(td.id)" :disabled="td.status != 'pending'">
                    Ver
                    <i class="mdi mdi-arrow-right ml-1"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal v-model="deposit.modal" modal-class="modal-right" hide-header hide-footer>
      <div v-if="deposit.payment.qrcode" class="text-center">
        <img style="width: 250px" :src="'data:image/jpeg;base64,' + deposit.payment.base64" />
        <p>Leia o qrcode acima ou</p>
        <ol class="text-left">
          <li>Abra o app do seu banco ou seu app de pagamentos.</li>
          <li>Busque a opção de pagar com pix.</li>
          <li>Copie e cole o seguinte código.</li>
        </ol>
        <div class="border rounded p-3 mb-3 text-left" style="overflow: hidden;">
          {{ deposit.payment.qrcode }}
        </div>
        <button class="btn btn-default" v-clipboard:copy="deposit.payment.qrcode" v-on:click="$toast.success('Copiado!')">
          <i class="bx bx-copy font-size-18 align-middle"></i>
          Copiar
        </button>
        <div class="d-flex mt-3">
          <a class="btn btn-outline-link" v-on:click="deposit.modal = false;">Fechar <sup>ESC</sup></a>
        </div>
      </div>
      <div v-else class="text-center">
        <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
      </div>
    </b-modal>
  </Layout>
</template>
